<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="transparent" outlined>
          <v-card-title> Start </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" v-if="abledJobs">
                <v-btn width="100%" @click="goToJob()"> Jobs </v-btn>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-btn width="100%" @click="goToNewTemplate()">
                  Maak een template
                </v-btn>
              </v-col> -->
              <v-col cols="12" sm="6" md="4" v-if="abledTemplates">
                <v-btn width="100%" @click="goToChooseTemplate()">
                  Templates
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="abledProposSettings">
                <v-btn
                  width="100%"
                  @click="goToBeheerVanRoutingStepTemplates()"
                >
                  Beheer van Cellen,
                  <br />
                  Operaties en Resources
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="abledCompanySettings">
                <v-btn width="100%" @click="goToCompanySettings()">
                  Company Settings
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    abledJobs() {
      return this.$store.getters.getAbledJobsSetting;
    },
    abledTemplates() {
      return this.$store.getters.getAbledTemplatesSetting;
    },
    abledProposSettings() {
      return this.$store.getters.getAbledProposSettingsSetting;
    },
    abledCompanySettings() {
      return this.$store.getters.getAbledCompanySettingsSetting;
    },
  },
  methods: {
    goToJob() {
      this.$router.push({ name: "job" });
    },
    goToNewTemplate() {
      const job = null;
      this.$store.commit("SET_JOB", {
        job,
      });
      this.$router.push({ name: "templates/board" });
    },
    goToChooseTemplate() {
      const job = null;
      this.$store.commit("SET_JOB", {
        job,
      });
      this.$router.push({ name: "templates/routingTypes" });
    },
    goToBeheerVanRoutingStepTemplates() {
      const job = null;
      this.$store.commit("SET_JOB", {
        job,
      });
      this.$router.push({ name: "beheer" });
    },
    goToCompanySettings() {
      this.$router.push({ name: "companySettings" });
    },
  },
};
</script>
